import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Button } from '@solana/wallet-adapter-react-ui/lib/types/Button';
import { Alert, Form, ListGroup } from 'react-bootstrap';

import './css/bootstrap.css'
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,

} from '@solana/wallet-adapter-wallets';
import fs from "fs";

import { clusterApiUrl, Transaction, SystemProgram, Keypair, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo, useCallback, useState } from 'react';

import { actions, utils, programs, NodeWallet, Connection} from '@metaplex/js'; 
import Network from 'arweave/node/network';
import { type } from 'os';
import { sign } from 'crypto';

import distrubute from './distribute';

require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');
let thelamports = 5;
let theWallet = "51h9EyD4h1kc5dVrgkw8M6mZMNag8ERk2BZnjXU6cqS2"
var enc = new TextEncoder();
let house = Keypair.generate();
function getWallet(){

    
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const App: FC = () => {


    return (
        <Context>
            <Content />
        </Context>
    );
};


export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new LedgerWalletAdapter(),
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolletExtensionWalletAdapter(), 
            new SolletWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
        ],
        [network]
    );

   

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const Content: FC = () => {
    let wallets: string[] = [];
    let [lamports, setLamports] = useState(.1);
    let [wallet, setWallet] = useState(theWallet);
    const [operation, setOperation] = useState('multiply');

    const handleToggle = () => {
        if (operation === 'multiply') {
          setOperation('add');
        } else {
          setOperation('multiply');
        }
      }

    
    var len = wallets.length;
    

    // const { connection } = useConnection();
    const connection = new Connection(WalletAdapterNetwork.Mainnet)
    const { publicKey, sendTransaction } = useWallet();


    const click = useCallback( async () => {
        console.log(house.publicKey.toString())
        if (!publicKey) return alert("Wallet not connected");
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey(theWallet),
                lamports: thelamports*LAMPORTS_PER_SOL,
            })
        )
        const signature = await sendTransaction(transaction, connection);
        console.log(signature)
        alert(`done. your signature is ${signature}. you can redeem it using /purchase.`)
        }, [publicKey, sendTransaction, connection]);


    function get_fee_ammount() {
        if (len >= 0 && len <= 100) {
            return 0.05;
        }
        else if (len >= 101 && len <= 200) {
            return 0.1;
        }
        else if (len >= 201 && len <= 300) {
            return 0.15;
        }
        else if (len >= 301 && len <= 400) {
            return 0.2;
        }
        else if (len >= 401 && len <= 500) {
            return 0.25;
        }
        else if (len >= 501 && len <= 600) {
            return 0.3;
        }
        else if (len >= 601) {
            return 1;
        }
    }
    
    function setTheLamports(e: any)
    {
        console.log(Number(e.target.value));
        setLamports(Number(e.target.value));
        lamports = Number(e.target.value);
        thelamports = lamports;
    }
    function setTheWallet(e: any){
        setWallet(e.target.value)
        theWallet = e.target.value;
    }
    
    let showFile = async (e: any | null) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => { 
            var text = (e.target!.result)
            if (!text) return;
            wallets = text.toString().split("\n");
            console.log(wallets);
            len = wallets.length;
        };
        reader.readAsText(e.target.files[0])
        return 
      }


    return (
        <div>
        <header>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </header>
        

        <div className="App">
            <div className="navbar">
                <div className="navbar-inner ">
                <a id="title" className="brand" href="https://copperheadsoftware.co/">Copperhead Software</a>
                <ul className="nav">

                </ul>
                <ul className="nav pull-right">
                            <li><WalletMultiButton /></li>
                </ul>
                </div>
            </div>
            <div className='title_ele'><h1 id='title'>Whitestone<br/>EagleEye toolset</h1></div>
            <div className="container">
                <div className='operator'>
                    <select name="term" id="keyTerm" onChange={(e) => setTheLamports(e)}>
                        <option value="5">1 Week</option>
                        <option value="17">1 Month</option>
                        <option value="40">1 Year</option>
                    </select>
                </div>
                <br />
                <button className='btn' onClick={click}>Purchase key</button>
            </div>
    </div>
</div>
    );
};
